import { useLayoutEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Carousel from '../components/carousel'
import React from 'react'
import DynamicZone from '../helpers/dynamicZone'

const InternalPage = (props) => {
  const { pageContext, data } = props
  const { navbar } = pageContext
  const {
    strapiPages: page,
    strapiOrganizationalUnit: organizationalUnit
  } = data
  const { components } = page

  useLayoutEffect(() => {
    const body = document.getElementsByTagName('body')
    body[0].setAttribute('class', 'fix-internalpages-without-header')

    return () => {
      body[0].removeAttribute('class', 'fix-internalpages-without-header')
    }
  })

  let SEO = ''

  if (page.pageMetadata !== null) {
    const { description, keywords, pageTitle } = page.pageMetadata

    SEO = (
      <Seo
        title={`${pageTitle}`}
        description={`${description}`}
        keywords={`${keywords}`}
      />
    )
  }

  return (
    <Layout page={navbar || organizationalUnit} scriptSelect={page?.scripts}>
      {SEO}
      <section>
        {page.header?.visible && (
          <div className="d-flex flex-column header-carousel">
            <Carousel carousel={page.header} keyName={'internalPage-'} />
          </div>
        )}
        <div className="pt-5">
          <DynamicZone components={components} />
        </div>
        <div className="container pt-5"></div>
      </section>
    </Layout>
  )
}

export default InternalPage

InternalPage.propTypes = {
  pageContext: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
}

export const internalPageQuery = graphql`
  query($pageId: Int) {
    strapiPages(strapiId: { eq: $pageId }) {
      pageMetadata {
        description: pageDescription
        keywords: pageKeywords
        pageTitle
      }
      name
      slug
      header {
        visible
        slide {
          content
          name
          url
          visible
          imageFilter
          imageDescription
          textButton
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          ucc_career {
            slug
          }
          novelty {
            slug
          }
          page {
            slug
          }
          diary {
            slug
          }
          organizational_unit {
            slug
          }
        }
      }
      scripts {
        source
        id
        enable
        name
      }
      components {
        title
        visible
        strapi_component
        id
        rightText
        leftText
        formTitle
        text
        carouselCard {
          color
          description
          labelBlack
        }
        icons {
          alt
          content
          icon {
            code
            type
            name
          }
        }
        accordion {
          title
          summary
          id
        }
        iconTexts {
          id
          alt
          content
          icon {
            code
            type
          }
        }
        textContent {
          id
          content
          variant
          backgroundImage{
            url
          }
          title
          visible
        }
        videoBack {
          id
          description
          videoUrl
          buttonVideo {
            url
            content
            page {
              slug
            }
          }
          backgroundImage {
            url
          }
          video {
            url
          }
        }
        tabs {
          id
          title
          content
          itemsAccordion {
            id
            title
            content
          }
        }
        singleImage {
          id
          title
          visible
          image {
            url
          }
        }
        video {
          id
          visible
          title
          description
          videoId
          mediaVideo {
            url
          }
        }
        map {
          id
          code
          title
          visible
        }
        MapContent {
          id
          code
          title
          visible
          mapContentText {
            id
            address
            contact
            openingHours
            image {
              url
            }
          }
        }
        pdf {
          id
          code
          title
          visible
        }
        audioList {
          id
          code
          title
          visible
        }
        calendar {
          calendarUrl
          id
          code {
            code
            title
            visible
          }
        }
        brick {
          activateVideo
          content
          id
          imageName
          videoId
          visible
          image {
            url
          }
        }
        eventualitiesList {
          id
          description
          destacado
          name
          title
          visible
          image {
            name
            url
          }
        }
        transitionContent {
          text
          imagePosition
          image {
            url
          }
          color
        }
        heroContent {
          id
          title
          summary
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        diagonalContent {
          id
          title
          variant
          image {
            url
          }
          imageDark {
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
        }
        BannerListContent {
          id
          title
          Card {
            id
            title
            description
            url
            icon {
              url
            }
            page {
              name
              slug
            }
            organizational_unit {
              name
              slug
            }
          }
        }
        quoteContent {
          title
          description
          id
          variant
          videoUrl
          image {
            name
            url
          }
          dynamicButton {
            icon {
              code
              type
            }
            customButton {
              shape
              size
              variant
            }
            button {
              url
              label
              career_level {
                name
              }
              diary {
                slug
              }
              label
              novelty {
                slug
              }
              organizational_unit {
                slug
              }
              page {
                slug
              }
              ucc_career {
                slug
              }
            }
          }
          professional {
            name
            profilePicture {
              url
            }
          }
        }
        logoSection {
          title
          summary
          media {
            name
            img {
              url
            }
            imageDark {
              url
            }
          }
        }
        cards {
          cardName
          content
          destacado
          id
          type
          url
          visible
          color
          labelBlack
          backgroundImageName
          backgroundImage {
            url
            name
          }
          page {
            slug
          }
          career_level {
            name
          }
          organizational_unit {
            slug
          }
        }
        slide {
          id
          name
          content
          imageDescription
          textButton
          url
          visible
          imageFilter
          backgroundImage {
            name
            url
          }
          backgroundVideo {
            url
          }
          diary {
            slug
          }
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
        }
        socialNetworks {
          title
          visible
          facebook {
            code
            visible
          }
          instagram {
            code
            visible
          }
          twitter {
            code
            visible
          }
        }
        form {
          id
        }
        interest_receiver {
          option
          receiver
        }
        interest_type {
          option
          type
        } 
        label
        icon {
          code
          type
        }
        button {
          career_level {
            name
          }
          diary {
            slug
          }
          label
          novelty {
            slug
          }
          organizational_unit {
            slug
          }
          page {
            slug
          }
          ucc_career {
            slug
          }
          url
        }
        customButton {
          shape
          size
          variant
        }
        profesionals {
          id
          rol
          visible
          professional {
            id
            name
            description
            profilePictureName
            profilePicture {
              url
              name
            }
          }
        }
        buildingsLocationList {
          title
          visible
          buildingsLocation {
            buildingName
            url
            information
          }
        }        
      }
    }
    allStrapiForm {
      nodes {
        strapiId
        nameForm
        uniqueReceiver
        interest_receiver {
          option
          receiver
        }
        name {
          name
          placeholder
        }
        email {
          name
          placeholder
        }
        phone {
          name
          placeholder
        }
        bodyForm {
          strapi_component
          name
          dropdownOptions {
            option
          }
          placeholder
          required
        }
      }
    }
    strapiOrganizationalUnit(pages: { elemMatch: { id: { eq: $pageId } } }) {
      name
      slug
      navbar {
        url
        name
        icon {
          code
          type
        }
        ExternalLink {
          name
          url
          id
          icon {
            code
            type
          }
        }
        page {
          name
          slug
          id
          organizational_unit
        }
        organizational_unit {
          name
          slug
          id
        }
        organizationalUnit {
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            id
            slug
          }
          id
        }
        pages {
          name
          id
          icon {
            code
            type
          }
          page {
            id
            name
            slug
            organizational_unit
          }
        }
        careersList {
          id
          name
          ucc_careers {
            id
            name
            slug
          }
        }
      }
    }
  }
`
